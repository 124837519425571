import React from 'react';

import { LocaleDateVariant } from '../../types';
import { Box } from '../Box';
import TimeSelectorHorizontalScroll from '../HorizontalScroll/TimeSelectorHorizontalScroll';
import LocaleDate from '../LocaleDate';
import Typography from '../Typography';
import { TimeSelectorDay } from './TimeSelectorDay';

type TimeSelectorProps = {
    centerLabel?: boolean;
    data: {
        id?: string;
        boxHeight: string;
        color: string;
        dateKey: string | number;
        disabled?: boolean;
        label?: string;
        onClick: () => void;
        selected: boolean;
        value: number;
    }[];
    'data-testid': string;
    dateKey?: string;
    enableResizeObserver?: boolean;
    platform?: string;
    variant: LocaleDateVariant;
};

/**
 *
 * Takes in date/time values to render a list of elements that will update map data when clicked
 *
 */
export const TimeSelector = ({
    centerLabel,
    data,
    'data-testid': dataTestId,
    platform,
    variant
}: TimeSelectorProps) => {
    return (
        <Box key={platform} display="flex" data-testid={dataTestId}>
            <TimeSelectorHorizontalScroll
                centerAlignArrows
                elements={data.map((item) => {
                    return (
                        <Box
                            id={item.id}
                            key={item.dateKey}
                            width="60px"
                            textAlign="left"
                            gap={1}
                            display="flex"
                            flexDirection="column"
                            onClick={!item.disabled ? item.onClick : undefined}
                            marginRight={0.5}
                        >
                            <TimeSelectorDay
                                height={item.boxHeight}
                                color={item.color}
                                selected={item.selected}
                                date={centerLabel ? <LocaleDate date={item.value} variant={variant} /> : undefined}
                                disabled={item.disabled}
                            />
                            {!centerLabel && (
                                <Typography
                                    sx={{
                                        fontWeight: item.selected ? 700 : 400
                                    }}
                                >
                                    <LocaleDate date={item.value} variant={variant} />
                                </Typography>
                            )}
                        </Box>
                    );
                })}
            ></TimeSelectorHorizontalScroll>
        </Box>
    );
};
