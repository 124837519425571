import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Grid, Typography } from '@methanesat/ui-components';

import { methaneDiffuseRangeFilterKeys, methanePlumeRangeFilterKeys } from '../../consts';
import { useL4UnitsLabel, usePlumeEmissionsUnitsLabel, useTranslate } from '../../hooks';
import {
    selectAreaEmissionsWithinZoom,
    selectEmissionsMapLayerConfigs,
    selectPlumeFluxWithinZoom,
    setMethaneLayerConfig
} from '../../reducers';
import { MethaneDiffuseRangeFilterKey, MethaneLayerIds } from '../../types';
import { MethaneRateFilter } from './MethaneRateFilter';
import { analytics } from '../../utils';
import { AreaEmissionsProductToggle } from '../EmissionsMapLegend/AreaEmissionsProductToggle';
import { SHOW_L3_OPTION } from '../../environmentVariables';

interface EmissionRateLayerControlProps {
    id: string;
    checked: boolean;
    filtersPlume: MethaneDiffuseRangeFilterKey[];
    filtersArea: MethaneDiffuseRangeFilterKey[];
}

/**
 * Form for updating the configuration of a methane plume map layer.
 */
export const EmissionsLayerControl = ({
    id,
    checked,
    filtersArea,
    filtersPlume
}: EmissionRateLayerControlProps): ReactElement => {
    const t = useTranslate();
    const dispatch = useDispatch();

    const areaLayerEnabled = useSelector(selectEmissionsMapLayerConfigs)[MethaneLayerIds.areaEmissionRaster].enabled;
    const plumeLayerEnabled = useSelector(selectEmissionsMapLayerConfigs)[MethaneLayerIds.plumeEmissionRate].enabled;

    const plumeWithinZoom = useSelector(selectPlumeFluxWithinZoom);
    const areaEmissionsWithinZoom = useSelector(selectAreaEmissionsWithinZoom);

    const disabled = !checked || !(plumeWithinZoom || areaEmissionsWithinZoom);
    const l4UnitsLabel = useL4UnitsLabel();
    const plumeEmissionsUnitsLabel = usePlumeEmissionsUnitsLabel();

    return (
        <Grid container spacing={2}>
            {/* Filter methane by emission type and/or rate */}
            <Grid item xs={12}>
                <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            variant="subtitle2"
                            sx={(theme) => ({
                                color: disabled ? theme.palette.text.disabled : theme.palette.text.primary
                            })}
                        >
                            {t('emissionsMapPage.mapControls.methaneFilters.prompt')}
                        </Typography>
                    </Grid>
                    {/* point emission filter */}
                    <Grid item xs={12}>
                        <MethaneRateFilter
                            data-testid={'distinct-emissions-filter'}
                            id={`${id}-distinct-emissions`}
                            label={t('emissionsMapPage.mapControls.methaneFilters.plumeEmissionsTitle')}
                            prompt={`${t('emissionsMapPage.mapControls.methaneFilters.plumeEmissionsPrompt')}${plumeEmissionsUnitsLabel}`}
                            options={methanePlumeRangeFilterKeys}
                            selections={filtersPlume}
                            subFiltersEnabled={false}
                            layerEnabled={plumeLayerEnabled}
                            onEnabledChange={(value) => {
                                analytics.togglePlumeEmissionData({ visible: value });
                                dispatch(
                                    setMethaneLayerConfig({
                                        layerId: MethaneLayerIds.plumeEmissionRate,
                                        layerUpdates: { enabled: value }
                                    })
                                );
                            }}
                            onSelectionsChange={(value) =>
                                dispatch(
                                    setMethaneLayerConfig({
                                        layerId: MethaneLayerIds.plumeEmissionRate,
                                        layerUpdates: { filtersPlume: value }
                                    })
                                )
                            }
                            disabled={!plumeWithinZoom}
                            variant="distinct"
                        ></MethaneRateFilter>
                    </Grid>
                    {/* area emission filter */}
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={12}>
                                <MethaneRateFilter
                                    data-testid={'diffuse-emissions-filter'}
                                    id={`${id}-diffuse-emissions`}
                                    label={t('emissionsMapPage.mapControls.methaneFilters.areaEmissionsTitle')}
                                    prompt={`${t('emissionsMapPage.mapControls.methaneFilters.areaEmissionsPrompt')}${l4UnitsLabel}`}
                                    options={methaneDiffuseRangeFilterKeys}
                                    selections={filtersArea}
                                    subFiltersEnabled={false}
                                    layerEnabled={areaLayerEnabled}
                                    onEnabledChange={(value) => {
                                        analytics.toggleAreaEmissionData({ visible: value });
                                        dispatch(
                                            setMethaneLayerConfig({
                                                layerId: MethaneLayerIds.areaEmissionRaster,
                                                layerUpdates: { enabled: value }
                                            })
                                        );
                                    }}
                                    onSelectionsChange={(value) =>
                                        dispatch(
                                            setMethaneLayerConfig({
                                                layerId: MethaneLayerIds.areaEmissionRaster,
                                                layerUpdates: { filtersArea: value }
                                            })
                                        )
                                    }
                                    disabled={!areaEmissionsWithinZoom}
                                    variant="diffuse"
                                ></MethaneRateFilter>
                            </Grid>
                            {SHOW_L3_OPTION && (
                                <Grid item xs={12} ml={2}>
                                    <AreaEmissionsProductToggle location="drawer" />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
