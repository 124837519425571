import { BarGradientLabel, Box } from '@methanesat/ui-components';

import { useTranslate } from '../../../../../hooks';
import { getConfidencePercentage } from '../../../../../utils';
import { formatEmissions } from '../../../../../utils/numbers';

export const EmissionsHeadline = ({
    'data-testid': dataTestid,
    emissions,
    highEmissions,
    lowEmissions,
    units
}: {
    'data-testid'?: string;
    emissions: number;
    highEmissions?: number;
    lowEmissions?: number;
    units: string;
}) => {
    const t = useTranslate();
    const emissionsLabel = formatEmissions(emissions);

    const unitsLabel = units ? ` ${units}` : '';

    let confidenceLabel = '';
    const lowOrHighEmissions = lowEmissions ?? highEmissions;

    if (lowOrHighEmissions) {
        const confidencePercentage = getConfidencePercentage(emissions, lowOrHighEmissions);
        confidenceLabel = ` ${t('emissionsMapPage.mapModals.emissions.confidence', { confidencePercentage })}`;
    }

    const emissionsText = `${emissionsLabel}${unitsLabel}${confidenceLabel}`;

    return (
        <Box width="100%" data-testid={dataTestid}>
            <BarGradientLabel label={emissionsText} />
        </Box>
    );
};
