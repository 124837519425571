import React, { ReactElement, useEffect, useRef } from 'react';
import { Box } from '../Box';
import Typography from '../Typography';

type TimeSelectorDayProps = {
    color: string;
    text?: string;
    date?: string | ReactElement;
    height: string;
    selected: boolean;
    disabled?: boolean;
};
/**
 *
 * Takes in color and a selected value to render blocks of time/dates
 *
 */
export const TimeSelectorDay = ({ height, date, color, selected = false, disabled }: TimeSelectorDayProps) => {
    const selectorRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (selected) {
            selectorRef.current?.scrollIntoView({ behavior: 'instant', inline: 'center' });
        }
    }, [selected]);

    return (
        <Box
            sx={(theme) => ({
                minHeight: height,
                background: color,
                minWidth: '25px',
                padding: '8px',
                ':hover': !disabled
                    ? {
                          cursor: 'pointer',
                          outline: !selected ? `3px solid ${theme.palette.action.hover}` : undefined,
                          transition: 'outline .05s linear'
                      }
                    : undefined,
                borderRadius: 1,
                outline: selected ? `3px solid ${theme.palette.primary.main}` : undefined,
                stroke: color,
                opacity: disabled ? '0.5' : undefined
            })}
            ref={selectorRef}
        >
            {date && (
                <Typography
                    sx={{
                        fontWeight: selected ? 700 : 400,
                        textTransform: 'uppercase',
                        lineHeight: '16px'
                    }}
                >
                    {date}
                </Typography>
            )}
        </Box>
    );
};
