import { ReactElement, useEffect, useRef } from 'react';

import {
    Button,
    CircleIcon,
    CalendarMonthIcon,
    ClickAwayListener,
    ColorRangeIcon,
    ColorRangeIconScale,
    DispersedSourceIcon,
    FilterIcon,
    Grid,
    MarkdownFormatter,
    PlumeIcon,
    Typography,
    useTheme,
    ZoomIcon
} from '@methanesat/ui-components';
import { BelowNavDrawer, BelowNavDrawerProps } from '../BelowNavDrawer';
import { useAppDispatch, useCurrentBreakpoint, useTranslate } from '../../hooks';
import { toggleSingleChip } from '../../reducers';
import { tooltipSlice } from '../../reducers/tooltip';
import { getBreakpointsDown } from '../../utils/breakpoints';
import { getOgiColor } from '../EmissionsMapLegend/MiniLegend/OgiChip';
import { analytics, getColorRangeColors, getTargetTotalsColors } from '../../utils';
import { METHANESAT_FAQ_URL } from '../../consts';

type IntroDrawerProps = Omit<BelowNavDrawerProps, 'children'> & {
    onWidth: (width: number) => void;
};

/**
 * Full-height side drawer for map controls.
 * Under the app bar on larger screens,
 * truly full-height on mobile.
 */
const IntroDrawer = ({ onWidth, ...drawerProps }: IntroDrawerProps): ReactElement => {
    const t = useTranslate();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const drawerRef = useRef<HTMLDivElement>(null);

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;

    const ogiInfrastructureColor = getOgiColor(theme, true);

    // get current breakpoint - allows setting drawer width correctly, using `onWidth`
    const breakpoint = useCurrentBreakpoint();

    useEffect(() => {
        const down = getBreakpointsDown('drawer', theme);
        /**
         * when the drawer is full-screen, do not limit the bounding box
         * as users must always close the drawer to see the map
         */
        if (down.includes(breakpoint)) {
            onWidth(0);
        } else {
            onWidth(drawerWidth || 0);
        }
    }, [breakpoint, drawerWidth]);

    return (
        <BelowNavDrawer {...drawerProps} paperRef={drawerRef}>
            <Grid container spacing={1} paddingX={2} marginBottom={2}>
                {/** Header */}
                <Grid item xs={12}>
                    <Typography variant="h3" sx={{ letterSpacing: '-0.05rem' }}>
                        {t('emissionsMapPage.introDrawer.introHeader')}
                    </Typography>
                </Grid>
                {/** Caption */}
                <Grid item xs={12} sx={{ marginBottom: theme.spacing(1), display: 'flex' }}>
                    <Typography variant="caption">{t('emissionsMapPage.introDrawer.description')}</Typography>
                </Grid>

                {/** Explore the Map */}
                <Grid item xs={12} marginTop={1}>
                    <Typography variant="h5">{t('emissionsMapPage.introDrawer.explore')}</Typography>
                </Grid>

                <Grid item xs={12}>
                    {/* Data over time */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-data-over-time-button"
                            startIcon={<CalendarMonthIcon />}
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                {
                                    analytics.clickIntroZoom();
                                    dispatch(
                                        tooltipSlice.actions.show({
                                            targetSelector: '#date-option-1',
                                            text: t('emissionsMapPage.introDrawer.modals.changeDate')
                                        })
                                    );
                                }
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.dataOverTime')}
                        </Button>
                    </ClickAwayListener>
                    {/* zoom buttons */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-zoom-button"
                            startIcon={<ZoomIcon />}
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                {
                                    analytics.clickIntroZoom();
                                    dispatch(
                                        tooltipSlice.actions.show({
                                            targetSelector: '#map-zoom-in-button',
                                            text: t('emissionsMapPage.introDrawer.modals.zoomIn')
                                        })
                                    );
                                }
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.zoom')}
                        </Button>
                    </ClickAwayListener>
                </Grid>

                {/** Understanding data layers */}
                <Grid item xs={12} marginTop={1}>
                    <Typography variant="h5">{t('emissionsMapPage.introDrawer.understandingLayers')}</Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: theme.spacing(1), marginTop: theme.spacing(-1), display: 'flex' }}
                >
                    <Typography variant="caption">{t('emissionsMapPage.introDrawer.layerVisibility')}</Typography>
                </Grid>

                <Grid item xs={12}>
                    {/* regional emissions */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-regional-emissions-button"
                            startIcon={
                                <ColorRangeIcon
                                    colors={getTargetTotalsColors(theme, true)}
                                    scale={ColorRangeIconScale.linear}
                                />
                            }
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroRegionalEmissions();
                                dispatch(toggleSingleChip('isTargetEmissionsChipExpanded'));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#target-emissions-expand',
                                        parentSelector: '[data-testid="target-emissions"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.regionalEmissions')}
                        </Button>
                    </ClickAwayListener>
                    {/* area emissions */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-area-emissions-button"
                            startIcon={
                                <DispersedSourceIcon
                                    colors={getColorRangeColors(theme, true)}
                                    sx={{
                                        marginRight: '14px',
                                        marginLeft: '14px'
                                    }}
                                />
                            }
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroAreaEmissions();
                                dispatch(toggleSingleChip('isAreaEmissionsChipExpanded'));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#area-emissions-expand',
                                        parentSelector: '[data-testid="area-emissions"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.areaEmissions')}
                        </Button>
                    </ClickAwayListener>
                    {/* point souces */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-point-source-button"
                            startIcon={
                                <PlumeIcon
                                    sx={{
                                        marginRight: '14px',
                                        marginLeft: '14px'
                                    }}
                                    colors={getColorRangeColors(theme, true)}
                                />
                            }
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroPointSources();
                                dispatch(toggleSingleChip('isPlumeEmissionsChipExpanded'));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#plume-emissions-expand',
                                        parentSelector: '[data-testid="point-source-toggle-chip"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.pointSource')}
                        </Button>
                    </ClickAwayListener>
                    {/* ogi/types */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-reported-ogi-button"
                            startIcon={<CircleIcon sx={{ color: ogiInfrastructureColor, stroke: 'grey', width: 48 }} />}
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroOgi();
                                dispatch(toggleSingleChip('isOilAndGasTypeChipExpanded'));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#ogi-infrastructure-expand',
                                        parentSelector: '[data-testid="oil-and-gas-toggle-chip"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.filterOGI')}
                        </Button>
                    </ClickAwayListener>
                    {/* filter ogi */}
                    <ClickAwayListener
                        onClickAway={() => {
                            dispatch(tooltipSlice.actions.hide());
                        }}
                    >
                        <Button
                            data-testid="intro-drawer-filter-ogi-button"
                            startIcon={<FilterIcon sx={{ width: 48 }} />}
                            color="secondary"
                            fullWidth={true}
                            variant="contained"
                            onClick={(event) => {
                                event.stopPropagation();
                                analytics.clickIntroFilterOgi();
                                dispatch(toggleSingleChip('isOgiOperatorFilterExpanded'));
                                dispatch(
                                    tooltipSlice.actions.show({
                                        targetSelector: '#ogi-operator-filter-expand',
                                        parentSelector: '[data-testid="filter-ogi-chip"]'
                                    })
                                );
                            }}
                        >
                            {t('emissionsMapPage.introDrawer.filterOGIOperator')}
                        </Button>
                    </ClickAwayListener>
                </Grid>

                {/* Other questions */}
                <Grid item xs={12} marginTop={1}>
                    <Typography variant="h5">{t('emissionsMapPage.introDrawer.otherQuestions')}</Typography>
                </Grid>

                {/** Data FAQ */}

                {/** Info Links */}
                <Grid
                    container
                    padding={1}
                    paddingTop={0}
                    sx={{
                        lineHeight: '0.5rem'
                    }}
                >
                    <Grid item xs={11} sx={{ fontSize: '0.75rem' }}>
                        <MarkdownFormatter
                            markdown={t('emissionsMapPage.introDrawer.forMoreInfo', {
                                link: METHANESAT_FAQ_URL
                            })}
                            components={{
                                a: ({ ...props }) => (
                                    <a
                                        {...props}
                                        style={{ color: 'inherit', fontWeight: 'bold', lineHeight: '1.45rem' }}
                                        target="_blank"
                                        data-testid="intro-drawer-faq-link"
                                    />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </BelowNavDrawer>
    );
};

export default IntroDrawer;
