import { getLinearColorStops } from '@methanesat/colors';
import { roundUsingSigFigs } from '../../../utils/numbers';
import { useTranslate } from '../../../hooks';
import { MethaneLayerIds, Platforms } from '../../../types';
import { EMISSIONS_RANGES, getTargetColorRange } from '../../../consts';

export interface ColorStopLabel {
    /** Percentage for positioning. */
    percent: number;
    /** Label to display next to color stop. */
    label: string;
    /** Key for React element uniqueness. */
    key: string;
}

/** Custom values for the target color scale in the mini legend. These are assigned manually
 * since the spacing between them is irregular. They should be unique and sorted in ascending order.
 */
const CUSTOM_MAIR_TARGET_SCALE_VALUES = [10_000, 50_000, 100_000, 140_000];
/** Assign evenly spaced percentages for the labels */
const stopCount = CUSTOM_MAIR_TARGET_SCALE_VALUES.length;
export const evenlySpacedTargetScaleLabels: ColorStopLabel[] = CUSTOM_MAIR_TARGET_SCALE_VALUES.map((val, index) => {
    const label = roundUsingSigFigs(val);
    return {
        percent: parseFloat(((index / (stopCount - 1)) * 100).toFixed(2)),
        label,
        key: label
    };
});

/**
 * Creates labels for color stops from a min/max, segmenting linearly.
 */
export const getColorStopLabels = (
    min: number,
    max: number,
    colorStopCount = 4,
    ascending = false
): ColorStopLabel[] => {
    const stops = getLinearColorStops(colorStopCount, min, max);

    return stops.map((stopVal, index) => {
        const percentageStep = parseFloat(((index / (colorStopCount - 1)) * 100).toFixed(2));
        const label = Math.floor(stopVal).toLocaleString();

        return {
            percent: ascending ? 100 - percentageStep : percentageStep,
            label,
            key: label
        };
    });
};

/**
 * Adds to target labels "or more/less" text (using locales), tooltips, etc.  Intended to be used after running
 * getColorStopLabels().
 */
export const useAugmentLabels = (labels: ColorStopLabel[], unitsKey: string, tooltip: string) => {
    const t = useTranslate();
    return labels.map((labelObj) => {
        const { percent, label, key } = labelObj;

        // Highest value.
        if (percent === 100) {
            return {
                key,
                label: t('emissionsMapPage.miniLegend.generalEmissions.orMore', {
                    value: label,
                    unit: t(`emissionsMapPage.units.${unitsKey}`)
                }),
                percent,
                tooltip: t(tooltip)
            };
        }

        // Lowest value.
        if (percent === 0) {
            return {
                key,
                label: t('emissionsMapPage.miniLegend.generalEmissions.orLess', {
                    value: label
                }),
                percent
            };
        }

        // No-op passthrough.
        return labelObj;
    });
};

/** Generates color stop labels specific to a platform and type (target or dispersed sources). */
export const generateColorStopLabels = (platform: Platforms, layerId: MethaneLayerIds) => {
    let labels: ColorStopLabel[] = [];

    switch (layerId) {
        case MethaneLayerIds.areaEmissionRaster: {
            const { MAX } = EMISSIONS_RANGES[platform][MethaneLayerIds.areaEmissionRaster];
            // The min value for raster labels is a special case, because we want to show `1 or less` on the scale instead of `0`.
            labels = getColorStopLabels(1, MAX);
            break;
        }
        case MethaneLayerIds.targets: {
            // Specific handling for MAIR which uses predefined evenly spaced labels
            if (platform === Platforms.MAIR) {
                labels = evenlySpacedTargetScaleLabels;
            } else {
                const { MIN, MAX } = getTargetColorRange(platform);
                labels = getColorStopLabels(MIN, MAX);
            }
            break;
        }
    }
    return labels;
};
