import React from 'react';

import { Grid, InfoIcon, Link, MarkdownFormatter, SxProps, Typography } from '@methanesat/ui-components';

interface MoreInfoSectionProps {
    header?: string;
    content?: string;
    sx?: SxProps;
}

/** This component is used in the dispersed source and distinct source drawers to give the user more information about the data type they've clicked on.
 * It is the text within the yellow box.
 */
export const MoreInfoSection = ({ header, content, sx }: MoreInfoSectionProps) => {
    return (
        <Grid container sx={[{ marginTop: 4 }, ...(Array.isArray(sx) ? sx : [sx])]}>
            {header && (
                <Grid item xs={12}>
                    <Typography variant="h4">{header}</Typography>
                </Grid>
            )}
            {content && (
                <Grid item xs={12} paddingLeft={2} marginTop={1}>
                    <Typography variant="body1" component="div">
                        <MarkdownFormatter
                            components={{
                                a({ href, children }) {
                                    return (
                                        <Link href={href} target="_blank" underline="always">
                                            {children}
                                        </Link>
                                    );
                                }
                            }}
                            markdown={content}
                        />
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
};
