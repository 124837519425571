import { colorArrayToRgb } from '@methanesat/colors';
import { Box, Divider, Grid, MarkdownFormatter, Typography } from '@methanesat/ui-components';

import { basinLineColor, pipelineColor, plumeColor, pointInfrastructureColor } from '../../consts';
import { useL4UnitsLabel, usePlumeEmissionsUnitsLabel, useTranslate } from '../../hooks';
import { BelowNavDrawer } from '../BelowNavDrawer';
import ColorScheme from '../ColorScheme';
import { colorWithMapBackground } from '../../utils';
import { AREA_EMISSION_PALETTE, COLOR_STOPS } from '../../consts/colors';

interface EmissionsMapLegendProps {
    /** whether the legend is visible */
    isOpen: boolean;
    /** Closes the Legend */
    onClose: () => void;
    /** Test id for button */
    'data-testid': string;
}

/**
 * A legend for the layers on the emissions map
 */
const EmissionsMapLegend = ({ isOpen, onClose }: EmissionsMapLegendProps) => {
    const t = useTranslate();
    const areaEmissionsLabels = [...COLOR_STOPS].reverse();

    // We always assume the first color will represent negative values
    const colorPalette = AREA_EMISSION_PALETTE.map(colorWithMapBackground);

    // Header for the legend
    const legendHeader = (
        <Grid item xs={12}>
            <Typography variant="h3">{t('emissionsMapPage.legend.information')}</Typography>
        </Grid>
    );

    // Description of MethaneSAT data display
    const legendDescription = (
        <Grid item xs={12}>
            <Typography sx={{ fontWeight: 'normal' }} variant="subtitle1">
                <MarkdownFormatter markdown={t('emissionsMapPage.legend.methaneSATDescription')} />
            </Typography>
        </Grid>
    );

    const emissionSymbolWidth = 2;
    const emissionDescriptionWidth = 12 - emissionSymbolWidth;
    // Description of Plume Emissions
    const plumeEmissionsUnitsLabel = usePlumeEmissionsUnitsLabel();
    const plumeKey = (
        <>
            <Grid
                item
                xs={emissionSymbolWidth}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    component="div"
                    sx={(theme) => ({
                        background: colorArrayToRgb(plumeColor),
                        height: theme.spacing(4),
                        width: theme.spacing(4),
                        borderRadius: theme.spacing(3),
                        border: `solid 1px ${theme.palette.text.primary}`,
                        marginLeft: 2.5
                    })}
                />
            </Grid>
            <Grid item xs={emissionDescriptionWidth}>
                <Typography sx={{ fontWeight: 'normal' }} component={'div'} variant="subtitle1">
                    <Box display="inline" fontWeight="bolder" textTransform="capitalize">
                        {t('emissionsMapPage.legend.plumeEmissions')}
                    </Box>
                    {' ('}
                    <Box display="inline" fontWeight="bolder">
                        {plumeEmissionsUnitsLabel}
                    </Box>
                    {'): '}
                    {t('emissionsMapPage.emissions.plumeDescription')}
                </Typography>
            </Grid>
        </>
    );

    const l4UnitsLabel = useL4UnitsLabel();
    // Description of Area Emissions
    const areaEmissionKey = (
        <>
            <Grid item xs={emissionSymbolWidth} sx={{ paddingRight: '2px', marginBottom: -2 }}>
                <div style={{ minWidth: '30px', position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
                    <ColorScheme
                        colorValues={colorPalette}
                        colorLabels={areaEmissionsLabels}
                        labelPosition="start"
                        border
                        isVertical={true}
                    />
                </div>
            </Grid>
            <Grid item xs={emissionDescriptionWidth} sx={{ display: 'flex', alignItems: 'center', marginBottom: -2 }}>
                <Typography sx={{ fontWeight: 'normal' }} component={'div'} variant="subtitle1">
                    <Box fontWeight="bolder" display="inline">
                        {t('emissionsMapPage.legend.areaEmissions')}
                    </Box>
                    {' ('}
                    <Box display="inline" fontWeight="bolder">
                        {l4UnitsLabel}
                    </Box>
                    {'): '}
                    {t('emissionsMapPage.emissions.areaDescription')}
                </Typography>
            </Grid>
        </>
    );

    // Oil and gas infrastruction description
    const oilAndGasDescription = (
        <>
            <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'normal' }} component={'div'} variant="subtitle1">
                    <MarkdownFormatter markdown={t('emissionsMapPage.legend.oilAndGasDescription')} />
                </Typography>
            </Grid>
        </>
    );

    const infrastructureSymbolWidth = 3;
    const infrastructureDescriptionWidth = 12 - infrastructureSymbolWidth;
    // Point Infrastructure description
    const pointInfrastructureKey = (
        <>
            <Grid
                item
                xs={infrastructureSymbolWidth}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box
                    component="div"
                    sx={(theme) => ({
                        background: colorArrayToRgb(pointInfrastructureColor),
                        height: theme.spacing(2),
                        width: theme.spacing(2),
                        borderRadius: theme.spacing(3),
                        border: `solid 1px ${theme.palette.text.primary}`,
                        marginLeft: 1
                    })}
                />
            </Grid>
            <Grid item xs={infrastructureDescriptionWidth}>
                <Typography sx={{ fontWeight: 'normal' }} component={'div'} variant="subtitle1">
                    <Box fontWeight="bolder" display="inline">
                        {t('emissionsMapPage.legend.pointInfrastructure')}
                    </Box>
                    <Box component="span" marginLeft={1}>
                        {t('emissionsMapPage.legend.pointInfrastructureDescription')}
                    </Box>
                </Typography>
            </Grid>
        </>
    );

    // Pipeline  shape & text
    const pipelineKey = (
        <>
            <Grid item xs={infrastructureSymbolWidth} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    component="div"
                    sx={(theme) => ({
                        background: colorArrayToRgb(pipelineColor),
                        height: theme.spacing(1),
                        width: '100%',
                        border: `solid 1px ${theme.palette.text.primary}`
                    })}
                />
            </Grid>
            <Grid item xs={infrastructureDescriptionWidth}>
                <Typography sx={{ fontWeight: 'bolder' }} variant="subtitle1">
                    {t('emissionsMapPage.legend.pipeline')}
                </Typography>
            </Grid>
        </>
    );

    // Basin boundaries  shape & text
    const basinKey = (
        <>
            <Grid item xs={infrastructureSymbolWidth} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    component="div"
                    sx={(theme) => ({
                        background: colorArrayToRgb(basinLineColor),
                        height: theme.spacing(1),
                        width: '100%',
                        border: `solid 1px ${theme.palette.text.primary}`
                    })}
                />
            </Grid>
            <Grid item xs={infrastructureDescriptionWidth}>
                <Typography sx={{ fontWeight: 'bolder' }} variant="subtitle1">
                    {t('emissionsMapPage.legend.basinBoundaries')}
                </Typography>
            </Grid>
        </>
    );

    /* Number of pixels between the belowNavbar top, and the bottom of the Drawer description
    Serves to ensure the header remains visible as the user scrolls through the information  */
    const BELOW_NAV_HEIGHT_PX = 169;

    return (
        <BelowNavDrawer onClose={onClose} open={isOpen}>
            {/* all legend items */}
            <Box>
                <Grid container spacing={1} sx={{ marginBottom: '10px', padding: '0px 10px 0px 10px' }}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {legendHeader}
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        height: { sm: 'calc(100vh - ' + BELOW_NAV_HEIGHT_PX + 'px);', xs: '100%' },
                        overflowY: 'auto',
                        padding: '0px 0px 80px 10px'
                    }}
                >
                    <Grid container rowSpacing={2} columnSpacing={2}>
                        {legendDescription}
                        {plumeKey}
                        {areaEmissionKey}
                        <Grid item xs={12}>
                            <Divider orientation="horizontal" sx={{ borderBottomWidth: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container rowSpacing={1} columnSpacing={2}>
                                {oilAndGasDescription}
                                {pointInfrastructureKey}
                                {pipelineKey}
                                {basinKey}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </BelowNavDrawer>
    );
};

export default EmissionsMapLegend;
