import React from 'react';
import { Box, SxProps } from '@mui/material';

const ProgressBar = ({ percent, sx }: { percent: number; sx?: SxProps }) => {
    return (
        <Box
            sx={[
                {
                    display: 'flex',
                    width: '100%',
                    gap: '4px'
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
        >
            <Box
                sx={(theme) => ({
                    height: '12px',
                    width: `${percent}%`,
                    background: theme.palette.progressBar.dark
                })}
            />
            <Box
                sx={(theme) => ({
                    height: '12px',
                    width: `${100 - percent}%`,
                    background: theme.palette.progressBar.light
                })}
            />
        </Box>
    );
};
export default ProgressBar;
