import { Box, Grid, ProgressBar, Typography } from '@methanesat/ui-components';
import { useTranslate } from '../../../../../hooks';
import { OGISummary } from '../../../../../types';

/**
 *
 * @param features: list of OGI features with layer_name properties
 * @returns Grid continer with grid items
 *
 * `features` are grouped by feature type. The list of items includes
 * two rows for each feature type: one grid item displaying the name & count of each type
 * and one displaying a single-value bar (`ProgressBar`) showing the type's % of total `features`.
 */

export const OGISummaryBars = ({ summary, total }: { summary: OGISummary; total: number }) => {
    const t = useTranslate();
    return (
        <Grid container flexDirection="column">
            {Object.entries(summary).map(([key, value]) => {
                const labelText = t(`emissionsMapPage.infrastructure.categoriesPlural.${key}`);
                return (
                    <Grid item xs={12} key={key} marginTop={2}>
                        {/* key & value labels above bar */}
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="subtitle2">{labelText}</Typography>
                            <Typography variant="subtitle2">{value}</Typography>
                        </Box>
                        {/* progress bar */}
                        <ProgressBar percent={(100 * value) / total} sx={{ gap: 0, marginTop: 1 }} />
                    </Grid>
                );
            })}
        </Grid>
    );
};
