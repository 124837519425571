import { Alert, Container } from '@methanesat/ui-components';
import { useEffect, useRef } from 'react';
import { useTranslate } from '../../../../hooks';
import { MSatPickInfo } from '../../../../types';
import { ErrorBoundary, handleErrorBoundaryError } from '../../../../utils';
import { BelowNavDrawer, BelowNavDrawerProps } from '../../../BelowNavDrawer';
import { LayerDataDrawerContent } from './LayerDataDrawerContent';

export interface LayerDataDrawerProps {
    info: MSatPickInfo | null;
    onClose: BelowNavDrawerProps['onClose'];
    open: boolean;
    onWidth: (w: number) => void;
}

/** Handles a single PickInfo object and passes it to LayerDataDrawerContent */
export const LayerDataDrawer = ({ info, onClose, open, onWidth }: LayerDataDrawerProps) => {
    const drawerRef = useRef<HTMLDivElement>(null);
    const t = useTranslate();

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;
    useEffect(() => {
        onWidth(drawerWidth || 0);
    }, [drawerWidth]);

    if (!info || !info?.layer.id) return <BelowNavDrawer open={false} onClose={onClose} />;

    return (
        <BelowNavDrawer data-testid="layer-data-drawer" onClose={onClose} open={open} paperRef={drawerRef}>
            <Container sx={{ marginTop: 2, marginBottom: 5 }}>
                {/* The key property resets the ErrorBoundary. This is because when the key changes,
                React thinks it's a different component and will re-render it, thus resetting it. */}
                <ErrorBoundary
                    key={info.object.id}
                    onError={handleErrorBoundaryError(`LayerDataDrawer ${info.object.id}`)}
                    fallbackRender={() => {
                        return (
                            <>
                                <Alert sx={{ marginTop: 6 }} severity="error">
                                    {t('emissionsMapPage.error.general')}
                                </Alert>
                            </>
                        );
                    }}
                >
                    {/*
                     * When open is false, it'll unmount the LayerDataDrawerContent
                     * This is one way we can tell from the child components when the
                     * drawer is closed.
                     */}
                    <LayerDataDrawerContent info={info} />
                </ErrorBoundary>
            </Container>
        </BelowNavDrawer>
    );
};
