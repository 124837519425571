import { Grid, InfoIcon, Tooltip, Typography } from '@methanesat/ui-components';
import { useTranslate } from '../../../../hooks';

export const MethaneModalHeader = ({
    collectionId,
    description,
    icon,
    title
}: {
    collectionId?: string;
    description?: string;
    icon?: JSX.Element;
    title: string;
}) => {
    const t = useTranslate();

    return (
        <>
            <Grid container justifyContent="space-between" paddingBottom={1}>
                <Grid item xs>
                    <Typography variant="h4" sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        {icon} {title}{' '}
                        {description && (
                            <Tooltip
                                title={<Typography fontSize={14}>{description}</Typography>}
                                sx={{ alignSelf: 'center', marginLeft: 1 }}
                            >
                                <InfoIcon />
                            </Tooltip>
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container>
                {collectionId && (
                    <Grid item xs>
                        <Typography variant="caption" fontStyle="italic">
                            {t('emissionsMapPage.mapModal.emissions.collectionId')}: {collectionId}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </>
    );
};
