import { Grid, Typography } from '@methanesat/ui-components';
import { OGIDetails, OGIDetailsExitButton, OGIMoreInfoButton, OGISummaryBars } from '.';
import { OGILayerIds, RawOGIData, OGISummary, PlumeDrawerProps } from '../../../../../types';
import { useClearedPreviousData, useGetOgiMetadata, useGetOgiNearPlumes, useTranslate } from '../../../../../hooks';
import { useDispatch } from 'react-redux';
import { analytics } from '../../../../../utils';
import { OGI_NEAR_PLUME_RADIUS_M } from '../../../../../consts';
import { setOgiNearPlumes } from '../../../../../reducers';

/** Ingests list of OGI features & returns a summary by type */
const getOgiSummary = (ogiList?: RawOGIData[]): OGISummary => {
    return ogiList
        ? ogiList
              .map((feature) => feature.layer_name)
              .reduce(
                  (acc: { [key in OGILayerIds]: number }, curr: OGILayerIds) => {
                      if (curr in acc) {
                          acc[curr]++;
                      } else {
                          acc[curr] = 1;
                      }
                      return acc;
                  },
                  {} as { [key in OGILayerIds]: number }
              )
        : {};
};

/** Gets OGI data for  */
function useGetOgiNearPlumesDrawer(info: PlumeDrawerProps['info']) {
    const [longitude, latitude] = info.object.geometry.coordinates;

    const newlyFetchedOgiNearPlumesList = useGetOgiNearPlumes({ longitude, latitude });
    // When params change, return undefined so we know when we are fetching data
    const ogiList = useClearedPreviousData(newlyFetchedOgiNearPlumesList, { longitude, latitude });

    const ogiSummary = getOgiSummary(ogiList);

    // TODO: fetch meatadata only when necessary (i.e., when user requests ogi details)

    // Get additional metadata for the OGIM data near plumes
    const ogimIdsToFetch = ogiList?.map(({ ogim_id }) => ogim_id);
    const fetchedOgiNearPlumesWithMetadata = useGetOgiMetadata({ ogimIds: ogimIdsToFetch || [] });

    // When params change, return undefined so we know when we are fetching data
    const ogiNearPlumesWithMetadata = useClearedPreviousData(fetchedOgiNearPlumesWithMetadata, {
        // sort ids so the list can be compared regardless of order,
        // and use a string, so testing equality is straightforward
        ogimIdsToFetch: ogimIdsToFetch?.toSorted().join(',')
    });

    // Get the layer types of the OGIM data that is near plumes indexed by the ogim id
    const ogiTypesNearPlume = ogiList?.reduce<{ [key: string]: OGILayerIds }>((accum, { ogim_id, layer_name }) => {
        accum[ogim_id] = layer_name;
        return accum;
    }, {});

    // Add the layer type of ogim data to the remaining metadata
    const ogiNearPlumesWithMetadataLayerId =
        ogiTypesNearPlume &&
        ogiNearPlumesWithMetadata?.map((ogi) => ({ ...ogi, layerName: ogiTypesNearPlume[ogi.ogim_id] }));

    return { ogiSummary, ogiDetailsList: ogiNearPlumesWithMetadataLayerId };
}

export const OGINearEmissions = ({
    info,
    showDetails,
    resetMap
}: {
    info: PlumeDrawerProps['info'];
    showDetails: boolean;
    resetMap: () => void;
}) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const [longitude, latitude] = info.object.geometry.coordinates;
    const { flux } = info.object.properties;

    const { ogiSummary, ogiDetailsList } = useGetOgiNearPlumesDrawer(info);
    const ogiCount = Object.values(ogiSummary).reduce((acc, curr) => (acc += curr), 0);

    const handleShowOgiDetails = () => {
        if (longitude !== undefined && latitude !== undefined && ogiDetailsList && !!flux) {
            // We add 0.01 degree to the longitude to make the map look centered when the drawer is open
            // TODO: handle this dynamically in the future
            dispatch(
                setOgiNearPlumes({
                    longitude: longitude + 0.01,
                    latitude,
                    ogimFeatures: ogiDetailsList
                })
            );
            analytics.showOgiNearPlumes({
                id: `${info.object.id}`,
                count: ogiDetailsList.length,
                emissionRate: flux,
                latitude: info.coordinate?.[1],
                longitude: info.coordinate?.[0]
            });
        }
    };

    const title = t('emissionsMapPage.infrastructure.ogiNearEmissions.nearbyFacilitiesTitle', {
        count: ogiCount
    });
    const subtitle = t('emissionsMapPage.infrastructure.ogiNearEmissions.plumes.nearbyFacilitiesDistanceNote', {
        radiusMeters: OGI_NEAR_PLUME_RADIUS_M
    });

    return (
        <Grid container marginTop={3}>
            {/* header & summary bars */}
            {!showDetails && (
                <>
                    {/* header */}
                    {title && (
                        <Grid item xs={12}>
                            <Typography variant="h5">{title}</Typography>
                        </Grid>
                    )}
                    {subtitle && (
                        <Grid item xs={12} display="flex" marginTop={1}>
                            <Typography variant="subtitle2">{subtitle}</Typography>
                        </Grid>
                    )}
                    {/* Show plume metadata & OGI summary */}
                    <Grid item xs={12} marginTop={1}>
                        <OGISummaryBars summary={ogiSummary} total={ogiCount} />
                    </Grid>
                </>
            )}

            {/* Show OGI details */}
            {showDetails && ogiDetailsList && (
                <Grid item xs={12} marginTop={4}>
                    <OGIDetails features={ogiDetailsList} />
                </Grid>
            )}

            {/* disclaimer */}
            <Grid item xs={12} marginTop={4}>
                <Typography>{t('emissionsMapPage.infrastructure.ogiNearEmissions.plumes.uncertaintyNote')}</Typography>
            </Grid>

            {/* Conditional buttons */}

            {/* Button to view ogi details
                            It is very important that ogiDetailsList is truthy. Otherwise button won't work */}
            {!showDetails && ogiDetailsList && (
                <Grid item xs={12} display="flex" justifyContent="center" marginTop={4} marginBottom={2}>
                    <OGIMoreInfoButton onClick={handleShowOgiDetails} data-testid="ogi-near-emissions-prompt-button" />
                </Grid>
            )}

            {/* Button to return to main plume drawer view */}
            {showDetails && (
                <Grid item xs={12} display="flex" justifyContent="center" marginTop={4} marginBottom={3}>
                    <OGIDetailsExitButton
                        data-testid="ogi-near-plumes-exit-button"
                        onClick={resetMap}
                        text={t('emissionsMapPage.infrastructure.ogiNearEmissions.plumes.returnToEmissions')}
                    />
                </Grid>
            )}
        </Grid>
    );
};
