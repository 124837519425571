import { useDispatch, useSelector } from 'react-redux';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@methanesat/ui-components';

import { useTranslate } from '../../hooks';
import {
    selectMethaneProduct,
    selectAreaEmissionsWithinZoom,
    selectEmissionsMapLayerConfigs,
    setAreaEmissionsProduct
} from '../../reducers';
import { RootState } from '../../store';
import { AreaEmissionsProducts, MethaneLayerIds } from '../../types';
import { analytics } from '../../utils';

const isAreaEmissionsProduct = (value: string): value is AreaEmissionsProducts => {
    return Object.values<string>(AreaEmissionsProducts).includes(value);
};

export const AreaEmissionsProductToggle = ({ location }: { location: string }) => {
    const t = useTranslate();
    const dispatch = useDispatch();
    const product = useSelector(selectMethaneProduct);
    const areaEmissionLayerEnabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[MethaneLayerIds.areaEmissionRaster].enabled
    );
    const areaEmissionsWithinZoom = useSelector(selectAreaEmissionsWithinZoom);

    const onProductChange = (newValue: string) => {
        if (!isAreaEmissionsProduct(newValue)) {
            throw new Error(`Invalid product: ${newValue}`);
        }

        analytics.toggleAreaEmissionsProduct({ source: location, value: newValue });
        dispatch(setAreaEmissionsProduct(newValue));
    };

    return (
        <FormControl disabled={!(areaEmissionLayerEnabled && areaEmissionsWithinZoom)}>
            <RadioGroup
                aria-label="Select emissions product"
                data-testid="emissions-product-radio-group"
                onChange={(_e, newProduct) => onProductChange(newProduct)}
                value={product}
            >
                <FormControlLabel
                    componentsProps={{ typography: { variant: 'body2' } }}
                    control={<Radio sx={{ padding: '2px 2px 2px 8px' }} size="small" />}
                    label={t(`emissionsMapPage.miniLegend.area-emission-raster.l4Label`)}
                    value={AreaEmissionsProducts.l4}
                />
                <FormControlLabel
                    componentsProps={{ typography: { variant: 'body2' } }}
                    control={<Radio sx={{ padding: '2px 2px 2px 8px' }} size="small" />}
                    label={t(`emissionsMapPage.miniLegend.area-emission-raster.l3Label`)}
                    value={AreaEmissionsProducts.l3}
                />
            </RadioGroup>
        </FormControl>
    );
};
