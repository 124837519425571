import { colorArrayToHex, grey11 } from '@methanesat/colors';

const grey11Hex = grey11.map(colorArrayToHex);

/**
 * Builds a linear css gradient string for use
 *
 * @param colors list of css color strings. may be hex colors, rgb(a) or valid color names
 * @returns horizontal linear gradient with colors equally spaced, left to right.
 *
 * if @param colors is omitted, returns an 11-stop grey gradient
 *
 */
export const buildLinearGradient = (colors: string[] = grey11Hex) => {
    let gradientString = `linear-gradient(90deg, `;
    const stepSize = 100 / (colors.length - 1);

    for (let i = 0, l = colors.length; i < l - 1; i++) {
        const color = colors[i];
        gradientString += `${color} ${stepSize * i}%, `;
    }
    gradientString += `${colors[colors.length - 1]} 100%)`;

    return gradientString;
};
