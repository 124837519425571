import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectGlobalDate, selectPlatform, setFetchingDrawerInfo } from '../../reducers';
import {
    AreaEmissionsProducts,
    MethaneLayerIds,
    MSatPickInfo,
    PickInfoToPrioritize,
    PlumeEmissionPickInfo,
    PlumeProperties,
    SelectedFeatureUrlParams,
    StacFeature,
    StacFeatureWithLayerId,
    UrlFeatureTypes,
    UrlFeatureTypeToLayerId
} from '../../types';
import { getRasterPointDataFromStac, getStacItem } from './';

import type { RootState } from '../../store';
/**
 * Custom hook that retrieves layer information as a
 * `PickInfoToPrioritize` based on the selected STAC feature.
 * @returns The layer information or null (if there is no selection or an error).
 */
export const useGetLayerInfoFromUrl = () => {
    const dispatch = useDispatch();
    const { collectionId, itemId, featureType, coordinates, targetId, targetDate } = useSelector(
        (state: RootState) => state.pages.emissions.selectedFeature.selectedMethaneFeature
    );
    const selectedPlume = useSelector((state: RootState) => state.pages.emissions.selectedFeature.selectedPlume);
    const globalDate = useSelector(selectGlobalDate);
    const platform = useSelector(selectPlatform);

    const [layerInfo, setLayerInfo] = useState<PickInfoToPrioritize | null>(null);

    useEffect(() => {
        const fetchLayerInfo = async () => {
            if (collectionId && itemId && featureType && coordinates.length !== 0) {
                dispatch(setFetchingDrawerInfo(true));

                const stacFeatureType = UrlFeatureTypeToLayerId.get(featureType as UrlFeatureTypes);
                const searchParams: SelectedFeatureUrlParams = {
                    lat: coordinates[1],
                    lng: coordinates[0],
                    date: globalDate,
                    platform,
                    collectionId,
                    targetId,
                    itemId,
                    featureType,
                    targetDate
                };
                try {
                    let info: Partial<PickInfoToPrioritize> | null = null;
                    if (stacFeatureType === MethaneLayerIds.plumeEmissionRate) {
                        info = selectedPlume && getPlumeInfo(selectedPlume, targetId, itemId);
                    } else {
                        info = await buildLayerInfo(searchParams);
                    }
                    setLayerInfo(info as PickInfoToPrioritize);
                } catch (error) {
                    console.error('Error fetching layer info:', error); //eslint-disable-line
                    setLayerInfo(null);
                } finally {
                    dispatch(setFetchingDrawerInfo(false));
                }
            }
        };

        fetchLayerInfo();
    }, [collectionId, coordinates, itemId, featureType, selectedPlume, globalDate, platform]);

    return layerInfo;
};

/**
 * This function is used to build the drawer info from the URL for targets and area emissions.
 */
export const buildLayerInfo = async (
    searchParams: SelectedFeatureUrlParams
): Promise<Partial<PickInfoToPrioritize>> => {
    const { featureType, itemId, collectionId, platform, targetId, lat, lng } = searchParams;
    switch (featureType) {
        case UrlFeatureTypes.Target: {
            const product = getProductFromCollectionId(collectionId);
            try {
                const itemData: StacFeature = await getStacItem(itemId, product, platform);
                return {
                    layer: {
                        id: MethaneLayerIds.targets
                    } as MSatPickInfo['layer'],
                    object: itemData
                };
            } catch (error) {
                throw new Error('Error fetching target info');
            }
        }
        case UrlFeatureTypes.AreaEmission: {
            const info = await getAreaEmissionsInfo(searchParams);
            return {
                layer: {
                    id: MethaneLayerIds.areaEmissionRaster,
                    itemId,
                    targetId
                } as PickInfoToPrioritize['layer'],
                object: info,
                coordinate: [lng, lat]
            };
        }
        // Other layers won't be called with this function as they will not pass validation, but the
        // function can't lack an ending return statement as its return type does not include 'undefined'
        default:
            throw new Error(`Unsupported layer type: ${featureType}`);
    }
};

const getProductFromCollectionId = (collectionId: string) => {
    if (collectionId.includes('Level4')) return AreaEmissionsProducts.l4;
    if (collectionId.includes('Level3')) return AreaEmissionsProducts.l3;
    throw new Error(`Unsupported product in collectionId ${collectionId}`);
};

/**
 * Fetches the methane value, from a raster at a given coordinate,
 * as well as the properties of the STAC item that the area emissions belongs to.
 */
export const getAreaEmissionsInfo = async (params: SelectedFeatureUrlParams) => {
    const { lat, lng, platform, itemId, collectionId } = params;
    const product = getProductFromCollectionId(collectionId);

    try {
        const dataObject = await getRasterPointDataFromStac(lng, lat, itemId, product, platform);
        if (!dataObject) {
            throw new Error('No area emission found');
        }
        return dataObject;
    } catch (error) {
        throw new Error('Error fetching area emission info');
    }
};

/**
 * Formats the STAC feature for a plume into a `PickInfoToPrioritize` that can be used by `handleMapInfo`
 */
export const getPlumeInfo = (
    plume: StacFeatureWithLayerId<GeoJSON.Point, PlumeProperties>,
    targetId: string,
    itemId: string
): Partial<PlumeEmissionPickInfo> => {
    return {
        layer: {
            id: plume.layerId
        } as MSatPickInfo['layer'],
        object: {
            ...plume,
            targetId,
            itemId
        },
        ...(plume.index ? { index: plume.index } : {})
    };
};
