import { Alert, Box, Grid, Typography } from '@methanesat/ui-components';
import { useEffect, useRef } from 'react';

import { useTranslate } from '../../../../hooks';
import { MSatPickInfo } from '../../../../types';
import { analytics, ErrorBoundary, handleErrorBoundaryError } from '../../../../utils';
import { BelowNavDrawer } from '../../../BelowNavDrawer';
import { LayerDataDrawerProps } from './LayerDataDrawer';
import { LayerDataDrawerContent } from './LayerDataDrawerContent';

interface MultipleLayerDataDrawerProps extends Omit<LayerDataDrawerProps, 'info'> {
    infos: MSatPickInfo[];
}

/** Handles multiple PickInfo objects and passes each to LayerDataDrawerContent */
export const MultipleLayerDataDrawer = ({ infos, onClose, open, onWidth }: MultipleLayerDataDrawerProps) => {
    const hasInfo = !!infos && !!infos.length;
    const drawerRef = useRef<HTMLDivElement>(null);
    const t = useTranslate();

    useEffect(() => {
        infos &&
            infos.forEach((info) => {
                analytics.openMultiFeatureDetails({
                    layerId: info.layer.id,
                    featureId: `${info.object.id}`,
                    featureType: info.object.properties?.layerName,
                    latitude: info.coordinate?.[1],
                    longitude: info.coordinate?.[0]
                });
            });
    }, [infos]);

    const drawerWidth = drawerRef.current?.getBoundingClientRect().width;
    useEffect(() => {
        onWidth(drawerWidth || 0);
    }, [drawerWidth, onWidth]);

    if (!hasInfo) return <BelowNavDrawer open={false} onClose={onClose} />;

    return (
        <BelowNavDrawer data-testid="multiple-layer-data-drawer" onClose={onClose} open={open} paperRef={drawerRef}>
            <Box sx={{ margin: 2 }}>
                <ErrorBoundary
                    onError={handleErrorBoundaryError('MultipleLayerDataDrawer')}
                    fallbackRender={() => {
                        return (
                            <Alert sx={{ marginTop: 6 }} severity="error">
                                {t('emissionsMapPage.error.general')}
                            </Alert>
                        );
                    }}
                >
                    <>
                        <Grid container justifyContent="space-between" paddingBottom={1}>
                            <Grid item xs>
                                <Typography variant="h4">{infos.length} features selected</Typography>
                            </Grid>
                        </Grid>
                        {/*
                         * When open is false, it'll unmount the LayerDataDrawerContent
                         * This is one way we can tell from the child components when the
                         * drawer is closed.
                         */}
                        {infos.map((info, idx) => {
                            return <LayerDataDrawerContent key={idx} info={info} accordion={infos.length > 1} />;
                        })}
                    </>
                </ErrorBoundary>
            </Box>
        </BelowNavDrawer>
    );
};
