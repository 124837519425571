import { useState } from 'react';
import { ContentCopyIcon, Grid, IconButton, Paper, Tooltip, Typography } from '@methanesat/ui-components';

import { calculateMapZoomLevelForFeature, useLocaleRoute, useTranslate } from '../../../../hooks';
import { MethaneLayerIds, SelectedMethaneFeature } from '../../../../types';
import { formatDateForUrl, getMonthEnd } from '../../../../utils';

export const FeatureLink = ({
    collectionId,
    coordinates,
    date,
    featureLat,
    featureLng,
    featureType,
    itemId,
    label,
    layerId,
    platform,
    targetDate,
    targetId
}: SelectedMethaneFeature & {
    date: string;
    layerId: MethaneLayerIds;
    label: string;
    platform: string;
}) => {
    const t = useTranslate();
    const generateLocaleRoute = useLocaleRoute();
    const [isCopied, setIsCopied] = useState(false);
    const tooltipText = isCopied
        ? t('emissionsMapPage.mapModals.linksToFeatures.buttonFeedback')
        : t('emissionsMapPage.mapModals.linksToFeatures.tooltipTitle');

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareableURL);
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 2000);
    };

    const baseUrl = window.location.origin;
    const mapPath = generateLocaleRoute('/emissions-map/');
    const queryParams = new URLSearchParams({
        'view-latitude': coordinates[1].toFixed(5),
        'view-longitude': coordinates[0].toFixed(5),
        'view-zoom': calculateMapZoomLevelForFeature(layerId).toString(),
        date: formatDateForUrl(getMonthEnd(new Date(date))),
        platform: platform,
        'collection-id': collectionId || '',
        'item-id': itemId.toString(),
        'feature-type': featureType || '',
        ...(targetDate || date ? { 'feature-date': formatDateForUrl(new Date(targetDate || date)) } : {}),
        ...(targetId ? { 'feature-target': targetId } : {}),
        ...(featureLng ? { 'feature-lng': featureLng.toFixed(5) } : {}),
        ...(featureLat ? { 'feature-lat': featureLat.toFixed(5) } : {})
    });
    const shareableURL = `${baseUrl}${mapPath}?${queryParams.toString()}`;

    return (
        <Paper variant="elevation">
            <Grid container padding={2} justifyContent="space-between" alignItems="center">
                <Grid item xs>
                    <Typography variant="body2" fontStyle="italic" marginRight={1} sx={{ fontSize: 14.5 }}>
                        {`${label}, ${formatDateForUrl(new Date(date))}`}
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Tooltip title={tooltipText} enterTouchDelay={0} leaveDelay={500}>
                        <IconButton
                            data-testid="copy-feature-link"
                            onClick={copyToClipboard}
                            sx={{ marginY: '-20px', marginRight: -1 }}
                        >
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Paper>
    );
};
