import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Box, ColorRangeIcon, ColorRangeIconScale, Grid, Typography, useTheme } from '@methanesat/ui-components';

import { METHANESAT_FAQ_URL, PLATFORM_RASTER_SIZE } from '../../../../../consts';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { useAreaEmissionsUnitsLabel, useLengthUnitsLabel, useTranslate } from '../../../../../hooks';
import { selectMethaneProduct, selectPlatform, selectTargetDates } from '../../../../../reducers';
import { AreaEmissionDrawerProps, AreaEmissionsProducts, MethaneLayerIds, UrlFeatureTypes } from '../../../../../types';
import {
    analytics,
    formatCoordinates,
    formatMeasurementTime,
    getColorRangeColors,
    getFeatureLinkLabel
} from '../../../../../utils';
import { DrawerHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';
import { EmissionsHeadline } from './EmissionsHeadline';
import { MoreInfoSection } from './MoreInfoSection';

// TODO:  DP-1892 MethaneRasterDataDrawer and PlumeEmissionsDrawer are nearly identical and can
// likely be refactored to consolidate code
export const MethaneRasterDataDrawer = ({ info }: AreaEmissionDrawerProps) => {
    const theme = useTheme();

    const { coordinate, object } = info;

    const id = object.id;
    const collection = object.properties.collectionId;
    const collectionEndTime = object.properties.collectionEndTime;
    const collectionStartTime = object.properties.collectionStartTime;
    const platform = useSelector(selectPlatform);
    const label = getFeatureLinkLabel(platform, id, object.properties.sceneId);
    const targetDates = useSelector(selectTargetDates);
    const targetId = info.layer.targetId;

    const product = useSelector(selectMethaneProduct);
    useEffect(() => {
        analytics.openAreaEmissionDetails({
            emissionRate: info.object.properties.methane,
            /**
             * Likely unnecessary to find centroid.
             * For permian latitudes, if area is approx 1 km sq, there is no
             * difference in formatted coordinates of the centroid (calculated by turf.js)
             * and the coordinates of the point the user clicked
             */
            latitude: info.coordinate?.[1],
            longitude: info.coordinate?.[0]
        });
    }, [info.object, info.coordinate]);
    const t = useTranslate();

    // For drawer header
    const drawerTitle = t(`emissionsMapPage.mapModals.emissions.areaTitle.${product}`) || '';
    const iconColors = getColorRangeColors(theme, true);
    const iconColorScale = product === AreaEmissionsProducts.l4 ? ColorRangeIconScale.linear : ColorRangeIconScale.log;

    const descriptionText = t(`emissionsMapPage.mapModals.emissions.areaDescription.${product}`);

    const normalizedMethaneKgHrKm2 = info.object.properties.methane / Math.pow(PLATFORM_RASTER_SIZE[platform], 2);

    const lengthUnitsLabel = useLengthUnitsLabel();
    const detailDataRows = [
        ...(info.coordinate
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.common.coordinatesLabel'),
                      value: formatCoordinates(info.coordinate)
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.emissions.measurementTimeLabel'),
            value: formatMeasurementTime(collectionStartTime, collectionEndTime)
        },
        {
            label: t('emissionsMapPage.mapModals.common.dimensionsLabel'),
            value: `~ ${PLATFORM_RASTER_SIZE[platform]} ${lengthUnitsLabel} x ${PLATFORM_RASTER_SIZE[platform]} ${lengthUnitsLabel}`
        }
    ];
    const unitsLabel = useAreaEmissionsUnitsLabel(product);
    return (
        <>
            <Box>
                <DrawerHeader
                    title={drawerTitle}
                    icon={<ColorRangeIcon colors={iconColors} scale={iconColorScale} />}
                />
                {/* Emissions & confidence */}
                <Grid container marginTop={5} marginBottom={3} rowGap={2}>
                    <Grid item xs={12}>
                        <EmissionsHeadline
                            emissions={normalizedMethaneKgHrKm2}
                            units={unitsLabel}
                            data-testid="raster-emissionsheadline-value"
                        />
                    </Grid>
                    {descriptionText && (
                        <Grid item xs={12}>
                            <Typography variant="body1">{descriptionText}</Typography>
                        </Grid>
                    )}
                </Grid>

                {/* At a glance */}
                <Grid container paddingTop={3} paddingBottom={1}>
                    <Typography variant="h4">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
                </Grid>
                <DataRowsModalBody dataRows={detailDataRows} />

                <MoreInfoSection
                    header={t('emissionsMapPage.mapModals.dispersed.moreInfo.header')}
                    content={t('emissionsMapPage.mapModals.dispersed.moreInfo.content', {
                        link: METHANESAT_FAQ_URL,
                        sideLength: PLATFORM_RASTER_SIZE[platform]
                    })}
                />
                {SHOW_SHAREABLE_FEATURE_LINK && coordinate && (
                    <Grid container marginTop={3}>
                        {/* title */}
                        <Grid item xs={12} marginBottom={2}>
                            <Typography variant="h5">
                                {t(`emissionsMapPage.mapModals.linksToFeatures.${MethaneLayerIds.areaEmissionRaster}`)}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FeatureLink
                                collectionId={collection}
                                coordinates={coordinate}
                                date={collectionEndTime}
                                featureType={UrlFeatureTypes.AreaEmission}
                                itemId={id}
                                label={label}
                                layerId={MethaneLayerIds.areaEmissionRaster}
                                platform={platform}
                                targetDate={targetDates[targetId]}
                                targetId={targetId}
                            />
                        </Grid>
                    </Grid>
                )}
            </Box>
        </>
    );
};
