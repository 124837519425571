import dynamic from 'next/dynamic';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    BarGradient,
    Box,
    ColorRangeIcon,
    ColorRangeIconScale,
    getFormattedDate,
    Grid,
    LocaleDateVariant,
    PlumeIcon,
    ProgressBar,
    Typography,
    useTheme
} from '@methanesat/ui-components';
import { centroid, polygon } from '@turf/turf';

import { getTargetColorRange } from '../../../../../consts';
import { SHOW_SHAREABLE_FEATURE_LINK } from '../../../../../environmentVariables';
import { useGetHistoricalSamplesForTarget, useTranslate } from '../../../../../hooks';
import { selectPlatform, selectTargetDates, setSelectedFeatureParams, setTargetDate } from '../../../../../reducers';
import { CaptureFeatureProperties, MethaneLayerIds, STACCalendarData, UrlFeatureTypes } from '../../../../../types';
import { analytics, formatDateForUrl, getFeatureLinkLabel, getTargetTotalsColors } from '../../../../../utils';
import {
    formatEmissions,
    formatPercentage,
    formatTotalEmissionsMinified,
    isNumber,
    roundUsingSigFigs
} from '../../../../../utils/numbers';
import { formatMeasurementTime, getDayEnd } from '../../../../../utils/time';
import { DrawerHeader } from '../../Headers';
import { DataRowsModalBody, FeatureLink } from '../../Layout';

export interface TargetDrawerProps {
    properties: CaptureFeatureProperties | null;
    geometry: GeoJSON.Polygon;
    id: string;
}

/**
 *  Implementing next/dynamic here allows us to use enableResizeObserver within the HorizontalScroll component.
 *  enableResizeObserver helps with right scrolling issues within the TargertDrawer
 */
const DynamicTimeSelector = dynamic(() => import('@methanesat/ui-components').then((module) => module.TimeSelector), {
    ssr: false,
    loading: () => <p>Loading...</p>
});

export const TargetDrawer = ({ properties, id }: TargetDrawerProps) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const t = useTranslate();

    // analytics
    useEffect(() => {
        analytics.openTargetDetails({
            name: `${id}`
        });
    }, [id]);

    // mapping properties to new variable names
    const {
        basin: location,
        size_km2: sizeKm2,
        start_datetime: timeStart,
        end_datetime: timeEnd,
        net_total: totalKgHr,
        ps_count: pointSourceCount,
        ps_total: pointSourceTotalKgHr,
        target_id: targetId,
        coordinates: coordinates,
        collectionId: collectionId
    }: Partial<CaptureFeatureProperties> = properties as CaptureFeatureProperties;

    const targetDates = useSelector(selectTargetDates);

    const historicalTargetDates = useGetHistoricalSamplesForTarget(targetId);
    const selectorDates = historicalTargetDates?.toReversed() ?? [];
    const shouldShowTotalEmissions = isNumber(totalKgHr);
    const platform = useSelector(selectPlatform);
    const label = getFeatureLinkLabel(platform, id, location);
    const hasLinkProps = collectionId && coordinates && timeEnd && label;

    const { MIN, MAX } = getTargetColorRange(platform);

    const detailDataRows = [
        {
            label: t('emissionsMapPage.mapModals.target.measurementTaken'),
            value: formatMeasurementTime(timeStart, timeEnd)
        },
        ...(isNumber(sizeKm2)
            ? [
                  {
                      label: t('emissionsMapPage.mapModals.target.sizeLabel'),
                      value: roundUsingSigFigs(sizeKm2, 'km²')
                  }
              ]
            : []),
        {
            label: t('emissionsMapPage.mapModals.common.locationLabel'),
            value: location
        }
    ];

    // Updating the necessary state variables to open the drawer for the selected target
    const handleDayClick = (day: STACCalendarData) => {
        // setting date to very end of the selected day
        // for consistency with global date, which must set to end of day
        const endOfDay = getDayEnd(day.date).getTime();

        // since selector dates also include the features, get feature position information
        const selectedFeature = selectorDates.filter((t) => getDayEnd(t.date).getTime() === endOfDay)[0];
        const featurePolygon = polygon([selectedFeature.coordinates]);
        const [lng, lat] = centroid(featurePolygon).geometry.coordinates;

        // update target-specific date
        dispatch(setTargetDate({ date: endOfDay, targetId }));

        // update URL
        dispatch(
            setSelectedFeatureParams({
                itemId: selectedFeature.id,
                collectionId: selectedFeature.collection || null,
                featureType: UrlFeatureTypes.Target,
                coordinates: [lng, lat],
                targetDate: formatDateForUrl(new Date(endOfDay)),
                targetId: targetId
            })
        );
    };

    const data = selectorDates.map((date) => ({
        color: date.color,
        selected: date.id === id,
        label: getFormattedDate(date.date),
        dateKey: date.id,
        boxHeight: '50px',
        onClick: () => handleDayClick(date),
        value: date.date.getTime()
    }));

    const colorRangeIconColors = getTargetTotalsColors(theme, true);
    const dispersedEmissionsValueLabel = totalKgHr
        ? t('emissionsMapPage.mapModals.target.emissionsPerHour', {
              kgHr: formatEmissions(totalKgHr - (pointSourceTotalKgHr || 0))
          })
        : t('emissionsMapPage.mapModals.target.pendingData.general');

    // TODO: add when needed
    const descriptionText = t(`emissionsMapPage.miniLegend.target.description.${platform}`);
    return (
        <Box>
            <DrawerHeader
                title={`${t('emissionsMapPage.mapModals.target.title')}`}
                subtitle={`${platform} ${t('emissionsMapPage.mapModals.target.targetIdLabel')}: ${targetId}`}
                sx={{ marginBottom: 3 }}
            />
            {/* Total emissions visual */}
            <Grid container marginTop={2} rowSpacing={2}>
                <Grid item xs={12}>
                    {shouldShowTotalEmissions ? (
                        <BarGradient
                            colors={colorRangeIconColors}
                            min={MIN}
                            minLabel={formatTotalEmissionsMinified(MIN)}
                            max={MAX}
                            maxLabel={formatTotalEmissionsMinified(MAX)}
                            value={totalKgHr}
                            valueText={t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                kgHr: formatEmissions(totalKgHr || 0)
                            })}
                        />
                    ) : (
                        <BarGradient valueText={t('emissionsMapPage.mapModals.target.pendingData.total')} />
                    )}
                </Grid>
                {descriptionText && (
                    <Grid item xs={12}>
                        <Typography variant="body1">{descriptionText}</Typography>
                    </Grid>
                )}
            </Grid>

            {/* Target emissions composition */}
            <Grid container>
                {/* Title */}
                <Grid item xs={12} paddingTop={2} paddingBottom={1}>
                    <Typography variant="h4">
                        {t('emissionsMapPage.mapModals.target.emissionComposition.title')}
                    </Typography>
                </Grid>
                {/* Icons & percents */}
                <Grid item xs={12} paddingY={1} paddingBottom={1}>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: '6px'
                        }}
                    >
                        {/* Dispersed sources icon & percent */}
                        <Box sx={{ display: 'flex', gap: '4px' }}>
                            <ColorRangeIcon colors={colorRangeIconColors} scale={ColorRangeIconScale.linear} />
                            {totalKgHr && (
                                <Typography fontSize="16px" fontWeight={700}>
                                    {formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)}%
                                </Typography>
                            )}
                        </Box>
                        {/* Distince sources icon & percent */}
                        <Box sx={{ display: 'flex', gap: '4px' }}>
                            {totalKgHr && (
                                <Typography fontSize="16px" fontWeight={700}>
                                    {formatPercentage(pointSourceTotalKgHr || 0, totalKgHr)}%
                                </Typography>
                            )}
                            <PlumeIcon sx={{ height: 24 }} colors={colorRangeIconColors} />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} marginBottom={1}>
                    {/* 
                  progress bar: if we are missing the total, display an 'empty'/0% progress bar
                */}
                    {totalKgHr ? (
                        <ProgressBar percent={formatPercentage(totalKgHr - (pointSourceTotalKgHr || 0), totalKgHr)} />
                    ) : (
                        <ProgressBar percent={0} />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {/* data table for progress bar */}
                    <Grid container flexDirection="row" justifyContent="space-between" marginBottom={1}>
                        <Grid
                            item
                            xs
                            textAlign="start"
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column"
                        >
                            <Typography variant="subtitle1" fontWeight={700} marginY={1} lineHeight="1">
                                {t(`emissionsMapPage.mapModals.target.emissionComposition.areaEmissionsLabel`)}
                            </Typography>
                            <Typography variant="subtitle1">{dispersedEmissionsValueLabel}</Typography>
                        </Grid>
                        <Grid
                            item
                            xs
                            textAlign="end"
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="column"
                        >
                            <Typography variant="subtitle1" fontWeight={700} marginY={1} lineHeight="1">
                                {t(
                                    `emissionsMapPage.mapModals.target.emissionComposition.pointSourcesLabel.${pointSourceCount === 1 ? 'singular' : 'plural'}`,
                                    {
                                        pointSourceCount: pointSourceCount
                                    }
                                )}
                            </Typography>
                            <Typography variant="subtitle1">
                                {t('emissionsMapPage.mapModals.target.emissionsPerHour', {
                                    kgHr: formatEmissions(pointSourceTotalKgHr || 0)
                                })}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/** Historical Time Selector */}
            <Grid container paddingTop={2} paddingBottom={1}>
                <Grid item paddingBottom={1} xs={12}>
                    <Typography display={'inline-block'} variant="h4">
                        {t('emissionsMapPage.mapModals.common.overTimeTitle')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DynamicTimeSelector
                        data-testid="time-selector-day-picker"
                        data={data}
                        enableResizeObserver
                        variant={LocaleDateVariant.day}
                    />
                </Grid>
            </Grid>

            {/* At a glance */}
            <Grid container paddingTop={3} paddingBottom={1}>
                <Typography variant="h4">{t('emissionsMapPage.mapModals.common.dataTableTitle')}</Typography>
            </Grid>
            <DataRowsModalBody dataRows={detailDataRows} />

            {/* Shareable link to feature */}
            {SHOW_SHAREABLE_FEATURE_LINK && hasLinkProps && (
                <Grid container marginTop={3}>
                    {/* title */}
                    <Grid item xs={12} marginBottom={2}>
                        <Typography variant="h5">
                            {t(`emissionsMapPage.mapModals.linksToFeatures.${MethaneLayerIds.targets}`)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FeatureLink
                            collectionId={collectionId}
                            coordinates={centroid(polygon([coordinates])).geometry.coordinates}
                            date={timeEnd}
                            featureType={UrlFeatureTypes.Target}
                            itemId={id}
                            label={label}
                            layerId={MethaneLayerIds.targets}
                            platform={platform}
                            targetDate={targetDates[targetId]}
                            targetId={targetId}
                        />
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
