import { RootState } from 'packages/app/src/store';
import { SyntheticEvent, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { colorArrayToRgb } from '@methanesat/colors';
import {
    alpha,
    Badge,
    BadgeProps,
    Button,
    Checkbox,
    CheckBoxIcon,
    ChipSelect,
    ChipSelectLabel,
    FilterIcon,
    FormControlLabel,
    Grid,
    MarkdownFormatter,
    OgiLegendIcon,
    styled,
    Theme,
    Typography,
    ZoomInfoBox,
    useTheme
} from '@methanesat/ui-components';

import { ogiColor } from '../../../consts';
import { useTranslate } from '../../../hooks';
import {
    selectEmissionsMapInfraCategoryFilter,
    selectEmissionsMapLayerConfigs,
    selectEmissionsMapInterface,
    setCategoryFilter,
    toggleOgiInfrastructureChip
} from '../../../reducers';
import { Category, OGILayerIdOrder, OGILayerIds } from '../../../types';
import { analytics, colorWithMapBackground } from '../../../utils';
import { AdvancedChipFunctions } from './AdvancedChipFunctions';
import { SHOW_SELECT_DESELECT_IN_OGI_MINI } from '../../../environmentVariables';

export const getOgiColor = (theme: Theme, enabled: boolean) => {
    return enabled
        ? colorArrayToRgb(colorWithMapBackground(ogiColor))
        : alpha(colorArrayToRgb(colorWithMapBackground(ogiColor)), theme.palette.action.disabledOpacity);
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 5,
        top: 5,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0'
    }
}));

export const OgiChip = ({ disabled }: { disabled: boolean }) => {
    const t = useTranslate();
    const theme = useTheme();
    const dispatch = useDispatch();

    const categories = OGILayerIdOrder;

    const selectedOgiCategories = useSelector(selectEmissionsMapInfraCategoryFilter);
    const selectedOgiCategoryIds = useMemo(() => selectedOgiCategories.map(({ id }) => id), [selectedOgiCategories]);

    const enabled = useSelector(
        (state: RootState) => selectEmissionsMapLayerConfigs(state)[OGILayerIds.tileInfrastructure].enabled
    );
    const isOGITypeChipExpanded = useSelector(
        (state: RootState) => selectEmissionsMapInterface(state).ui.isOilAndGasTypeChipExpanded
    );
    const handleOgiChipExpanded = (_event: SyntheticEvent, isExpanded: boolean) => {
        dispatch(toggleOgiInfrastructureChip(isExpanded));
    };
    const updateOgiCategory = (checked: boolean, category: OGILayerIds) => {
        let newCategories: Category[];
        if (checked) {
            newCategories = [...selectedOgiCategories, { id: category }];
        } else {
            newCategories = selectedOgiCategories.filter(({ id }) => id !== category);
        }
        analytics.ogiFilterByCategory({ categories: newCategories.map(({ id }) => id) });
        dispatch(setCategoryFilter(newCategories));
    };

    const ogiInfrastructureColor = getOgiColor(theme, enabled);

    return (
        <ChipSelect
            data-testid="oil-and-gas-toggle-chip"
            disabled={disabled}
            expanded={isOGITypeChipExpanded}
            expandIconId="ogi-infrastructure-expand"
            label={
                <ChipSelectLabel
                    icon={
                        selectedOgiCategories.length !== categories.length ? (
                            <StyledBadge badgeContent={<FilterIcon sx={{ fontSize: 14 }} />} color="primary">
                                <OgiLegendIcon
                                    colors={[
                                        enabled
                                            ? colorArrayToRgb(colorWithMapBackground(ogiColor))
                                            : alpha(
                                                  colorArrayToRgb(colorWithMapBackground(ogiColor)),
                                                  theme.palette.action.disabledOpacity
                                              )
                                    ]}
                                />
                            </StyledBadge>
                        ) : (
                            <OgiLegendIcon
                                colors={[
                                    enabled
                                        ? colorArrayToRgb(colorWithMapBackground(ogiColor))
                                        : alpha(
                                              colorArrayToRgb(colorWithMapBackground(ogiColor)),
                                              theme.palette.action.disabledOpacity
                                          )
                                ]}
                            />
                        )
                    }
                >
                    {t('emissionsMapPage.miniLegend.oilAndGas.label')}
                </ChipSelectLabel>
            }
            onChange={handleOgiChipExpanded}
        >
            {disabled && (
                <ZoomInfoBox>
                    <strong>{t('emissionsMapPage.miniLegend.zoomIn')}</strong>{' '}
                    {t('emissionsMapPage.miniLegend.showOnMap')}
                </ZoomInfoBox>
            )}
            {categories.map((id) => (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={selectedOgiCategoryIds.includes(id)}
                            checkedIcon={<CheckBoxIcon />}
                            data-testid={`${id}-checkbox`}
                            name={id}
                            onChange={(ev) => {
                                updateOgiCategory(ev.target.checked, id);
                            }}
                            size="small"
                            sx={{
                                color: ogiInfrastructureColor,
                                '&.Mui-checked': {
                                    color: ogiInfrastructureColor
                                },
                                height: 20,
                                paddingLeft: 1.5,
                                '&:hover': {
                                    backgroundColor: 'transparent'
                                }
                            }}
                        ></Checkbox>
                    }
                    key={id}
                    label={t(`emissionsMapPage.infrastructure.categoriesPlural.${id}`)}
                    slotProps={{
                        typography: {
                            variant: 'body2',
                            // When the checkbox is disabled, do not gray out the text
                            sx: {
                                '&.MuiFormControlLabel-label.Mui-disabled': { color: 'text.primary' }
                            }
                        }
                    }}
                ></FormControlLabel>
            ))}
            {SHOW_SELECT_DESELECT_IN_OGI_MINI && (
                <Grid container direction="row" justifyContent="space-between" wrap="nowrap" marginTop={1}>
                    {/* select all categories */}
                    <Grid item xs={true}>
                        <Button
                            variant="text"
                            size="small"
                            data-testid="ogi-category-select-all"
                            onClick={() => {
                                dispatch(setCategoryFilter(categories.map((category) => ({ id: category }))));
                            }}
                            disabled={!enabled || selectedOgiCategories.length === categories.length}
                        >
                            <Typography variant="caption">Select all</Typography>
                        </Button>
                    </Grid>
                    {/* deselect all categories */}
                    <Grid item xs={true}>
                        <Button
                            data-testid="ogi-category-deselect-all"
                            variant="text"
                            size="small"
                            onClick={() => {
                                dispatch(setCategoryFilter([]));
                            }}
                            disabled={!enabled || selectedOgiCategories.length === 0}
                        >
                            <Typography variant="caption">Deselect all</Typography>
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Typography variant="body2">
                <MarkdownFormatter markdown={t('emissionsMapPage.miniLegend.oilAndGas.description')} />
            </Typography>
            <AdvancedChipFunctions
                label={enabled ? t('emissionsMapPage.miniLegend.hide') : t('emissionsMapPage.miniLegend.show')}
                layerEnabled={enabled}
                layerId={OGILayerIds.tileInfrastructure}
                analyticsEvent={analytics.toggleOilAndGasData.bind(analytics)}
            />
        </ChipSelect>
    );
};
